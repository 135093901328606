import { message, notification } from 'antd';

// const URL = REACT_APP_AUDIT_SVC;
const ErrCodeOK = 0;
const URL = process.env.NODE_ENV === 'development' ?
  'http://localhost:20000/' : `${window.location.origin}/`;


function fetchData(path: string, payload: any) : Promise<Response> {
  return fetch(`${URL}/${path}`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    mode: 'cors',
    body: JSON.stringify(payload)
  });
}

export function popupNotification(path: string, data: ({errCode: number, errMsg: string} | any)) : boolean {
  notification.destroy();
  if (data && data.errCode !== undefined &&
      data.errCode === ErrCodeOK) {
    return false;
  }
  notification.destroy();
  const errCode = data && data.errCode === undefined ? -1 : data.errCode;
  const errMsg = (data && data.errMsg && typeof data.errMsg === 'string') ?
    data.errMsg :
    (data && typeof data.stack === 'string' ? data.stack : JSON.stringify(data));
  notification.error({
    message: `${path}请求失败, 稍后重试`,
    description: `errCode:${errCode}\nerrMsg:${errMsg}`,
    style: {width: 500, whiteSpace: 'pre-line'},
  });
  return true;
}

interface IListReq {
  userName: string;
  currentPage: number;
};

export function fetchList(obj: IListReq) : Promise<any> {
  const path : string = 'audit/list';
  return new Promise((resolve, reject) => {
    fetchData(path, obj)
    .then(data => data.json())
    .then(data => {
      if (popupNotification(path, data)) {
        reject();
        return;
      }
      resolve({items: data.items});
    }).catch(err => {
      popupNotification(path, err);
      reject(err);
    });
  });
}

interface ISubmitReq {
  id: number;
  userName: string;
};

export function fetchSubmit(obj: ISubmitReq) : Promise<any> {
  const path : string = 'audit/submit';
  return new Promise((resolve, reject) => {
    message.destroy();
    const hide = message.loading('提交中..', 0);
    fetchData(path, obj)
    .then(data => data.json())
    .then(data => {
      hide();
      if (popupNotification(path, data)) {
        reject();
        return;
      }
      message.success('提交成功');
      setTimeout(() => resolve({}), 200);
    }).catch(err => {
      hide();
      popupNotification(path, err);
      reject(err);
    });
  });
}

interface IRejectReq {
  id: number;
  reason: string;
  userName: string;
};

export function fetchReject(obj: IRejectReq) : Promise<any> {
  const path : string = 'audit/reject';
  return new Promise((resolve, reject) => {
    message.destroy();
    const hide = message.loading('提交中..', 0);
    fetchData(path, obj)
    .then(data => data.json())
    .then(data => {
      hide();
      if (popupNotification(path, data)) {
        reject();
        return;
      }
      message.success('提交成功');
      setTimeout(() => resolve({}), 200);
    }).catch(err => {
      hide();
      popupNotification(path, err);
      reject(err);
    });
  });
}
