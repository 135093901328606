import React from 'react';
import { Button, Popconfirm } from 'antd';

import * as fetch from './fetch';

interface IProps {
  id: number;
  userName: string;
  reload: () => void;
};

export default class Submit extends React.Component<IProps> {
  fetchSubmit(id: number) {
    fetch
    .fetchSubmit({
      id,
      userName: this.props.userName
    })
    .then(() => this.props.reload())
    .catch(_ => {});
  }

  render() {
    return (
      <Popconfirm
        title='确认审批？'
        onConfirm={() => this.fetchSubmit(this.props.id)}
      >
        <Button type='link'>同意</Button>
      </Popconfirm>
    )
  }
};