import 'src/Main.css';
import { Buffer } from 'buffer';
import React from 'react';
import moment from 'moment';
import { Breadcrumb, Layout, List, Menu, Spin } from 'antd';
import { DesktopOutlined, ProfileOutlined } from '@ant-design/icons';

import Submit from 'src/Submit';
import Status from 'src/Status';
import Reject from 'src/Reject';
import * as fetch from 'src/fetch';

const BreadcrumbItem = Breadcrumb.Item;
const { Content, Sider } = Layout;

const ListItem = List.Item;
const ListItemMeta = List.Item.Meta;

interface IProps {};

interface IAuditItem {
  id: number;
  user: string;
  action: string;
  reason: string;
  rejectReason: string;
  status: number;
  reviewedBy: string;
  createdAt: string;
  updatedAt: string;
};

interface IState {
  loading: boolean;
  page: string;
  userName: string;
  currentPage: number;
  dataSource: Array<IAuditItem>;
};

export default class Main extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let userName = '';
    const user = localStorage.getItem('user');
    if (user && user.length > 0) {
      userName = Buffer.from(user, 'base64').toString();
    }

    this.state = {
      userName,
      loading: true,
      page: '操作审计',
      currentPage: 1,
      dataSource: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  format(time: string) {
    const d = new Date(time);
    return moment(d).format('YYYY-MM-DD HH:mm:ss');
  }

  fetchData() {
    this.setState({loading: true}, () =>
    fetch
    .fetchList({
      userName: this.state.userName,
      currentPage: this.state.currentPage
    })
    .then(data => this.setState({loading:false, dataSource: data.items.filter((val:IAuditItem) => this.state.page === '审计历史' ? 
      true : val.status === 0)}))
    .catch(_ => {}));
  }

  render() {
    return (
      <Layout hasSider>
        <Content style={{ padding: '0 50px', height: '100vh' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <BreadcrumbItem>主页</BreadcrumbItem>
            <BreadcrumbItem>
              {this.state.page}
            </BreadcrumbItem>
          </Breadcrumb>

          <Layout style={{ padding: '24px 0' }}>
            <Sider width={200} theme='light'>
              <Menu
                mode='inline'
                defaultSelectedKeys={['audit']}
                items={[
                  {
                    key: 'audit',
                    label: '操作审计',
                    icon: <DesktopOutlined />,
                    onClick: () => {this.setState({page: '操作审计'})}
                  },
                  {
                    key: 'history',
                    label: '审计历史',
                    icon: <ProfileOutlined />,
                    onClick: () => {this.setState({page: '审计历史'})}
                  }
                ]}
                onClick={() => this.fetchData()}
              />
            </Sider>
            <Content style={{ padding: '0 24px', minHeight: 280 }}>
              {
                this.state.loading &&
                <Spin size='large' tip='加载中...' style={{position:'relative', left:'50%', top:'50%'}} />
              }

              {
                !this.state.loading &&
                <List
                  bordered
                  itemLayout='horizontal'
                  dataSource={this.state.dataSource}
                  renderItem={item => (
                    <ListItem
                      actions={this.state.page === '审计历史' ? [<Status status={item.status} reason={item.rejectReason} reviewedBy={item.reviewedBy} />] : [
                        <Submit id={item.id} userName={this.state.userName} reload={() => this.fetchData()} />,
                        <Reject id={item.id} userName={this.state.userName} reload={() => this.fetchData()} />
                      ]}
                    >
                      <ListItemMeta
                        title={item.user + item.action}
                        description={
                          <span>
                            <p>{'原因:' + item.reason}</p>
                            <p>创建:{this.format(item.createdAt)} 更新:{this.format(item.updatedAt)}</p>
                          </span>
                        }
                      />
                    </ListItem>
                  )}
                />
              }
            </Content>
          </Layout>
        </Content>
      </Layout>
    );
  }
};
