import React from 'react';
import { Badge } from 'antd';

interface IProps {
  status: number;
  reason: string;
  reviewedBy: string;
};

export default class Status extends React.Component<IProps> {
  render() {
    let text = '';
    let status: 'default' | 'processing' | 'success' | 'error' | 'warning' | undefined = 'default';
    if (this.props.status === 0) {
      text = '待审核';
      status = 'processing';
    } else if (this.props.status === 1) {
      text = '已通过';
      status = 'success';
    } else if (this.props.status === 2) {
      text = '已驳回';
      status = 'error';
    }
    return (
      <span>
        <p> <Badge status={status} text={text} /> </p>
        {
          this.props.reason.length > 0 && this.props.reviewedBy.length > 0 &&
          <>
            <p>审核人:{this.props.reviewedBy}</p>
            <p>说明:{this.props.reason}</p>
          </>
        }
      </span>
    )
  }
};