import React from 'react';
import Main from 'src/Main';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

export default class App extends React.Component {
  navigateToSignIn(elem: JSX.Element) {
    const user = localStorage.getItem('user');
    if (!user || user.length === 0) {
      if (process.env.REACT_APP_LOGIN_ADDR) {
        window.location.href = process.env.REACT_APP_LOGIN_ADDR + '?to=' + encodeURIComponent(window.location.origin);
      }
    }

    const search = window.location.search;
    if (search.length > 0) {
      const searchParams = new URLSearchParams(search);
      const token = searchParams.get('token');
      if (token && token.length > 0) {
        localStorage.setItem('user', token);
      }
    }
    return (elem);
  };

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='*' element={this.navigateToSignIn(<Main />)} />
        </Routes>
      </BrowserRouter>
    );
  }
};
