import React from 'react';
import { Button, Form, FormInstance, Input, Popconfirm } from 'antd';

import * as fetch from './fetch';

interface IProps {
  id: number;
  userName: string;
  reload: () => void;
};

interface IState {
  visible: boolean;
};

const FormItem = Form.Item;

export default class Reject extends React.Component<IProps, IState> {
  private reasonForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = { visible: false};
    this.reasonForm = React.createRef<FormInstance>();
  }

  fetchReject(id: number, reason: string) {
    fetch
    .fetchReject({
      id,
      reason,
      userName: this.props.userName
    })
    .then(() => this.props.reload())
    .catch(_ => {});
  }

  render() {
    return (
      <Popconfirm
        visible={this.state.visible}
        title={
          <Form name='reasonForm' ref={this.reasonForm} validateTrigger='onBlur' initialValues={{ reason: '' }}>
            <FormItem name='reason' label='原因' rules={[{ required: true, min: 6, message: '原因过于简单' }]} tooltip='审批将被驳回'>
              <Input size='small' placeholder='驳回原因'/>
            </FormItem>
          </Form>
        }
        onConfirm={async () => {
          try {
            if (this.reasonForm.current) {
              const fields = await this.reasonForm.current.validateFields();
              this.setState({visible: false});
              const reason = fields.reason;
              this.fetchReject(this.props.id, reason);
            }
          } catch {}
        }}
        onCancel={() => this.setState({visible: false})}
      >
        <Button type='link' danger onClick={() => this.setState({visible: true})}>驳回</Button>
      </Popconfirm>
    )
  }
};